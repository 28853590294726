import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/kenneth/Sites/typebrigade/typebrigade.com/src/components/TemplateBlogPost.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Details for Speakers`}</h1>
    <p>{`Thanks so much for becoming a `}<strong parentName="p">{`Type Brigade`}</strong>{` speaker! Foremost, our goal is to remove any concerns you have around the event, so you can just focus on presenting. So: if you have any questions or concerns, `}<a parentName="p" {...{
        "href": "mailto:kenneth@typebrigade.com"
      }}>{`please email me`}</a>{` anytime.`}</p>
    <p>{`You can register for `}<a parentName="p" {...{
        "href": "http://www.meetup.com/typebrigade"
      }}>{`Type Brigade at meetup.com/typebrigade`}</a>{`, if you haven’t already. Then, you’ll get an email when we publicly announce the event.`}</p>
    <h2>{`Your talk`}</h2>
    <p>{`We have more details on the format of the talks on the `}<a parentName="p" {...{
        "href": "http://typebrigade.com/call-for-speakers"
      }}>{`Call for Speakers`}</a>{` page. In short, you have a 25–30 minute speaking slot followed by a 5 minute Q&A.`}</p>
    <p>{`To help us promote it, please fill out some details about your presentation for us—it doesn’t have to be perfect or finalised, just enough so we can announce the event.`}</p>
    <p>{`Following the form, `}<a parentName="p" {...{
        "href": "#more-details"
      }}>{`there’s more details`}</a>{` about what to expect as a speaker. Thanks again for participating!`}</p>
    {
      /*
      <form id="form2"
           name="form2"
           accept-charset="UTF-8"
           enctype="multipart/form-data"
           method="post"
           validate="validate"
           action="https://typebrigade.wufoo.com/forms/m1uocne51azfrmy/#public">
            <ul>
            <li>
           <label class="desc" for="Field1" id="title1" required="required">Name</label>
           <input class="field text medium" id="Field1" maxlength="255" name=
           "Field1" required="required" tabindex="1" type="text" value="" />
           </li>
            <li>
           <label class="desc" for="Field2" id="title2" required="required">Email</label>
           <input class="field text medium" id="Field2" maxlength="255" name=
           "Field2" required="required" spellcheck="false" tabindex="2" type="email"
           value="" />
           </li>
            <li>
           <label class="desc" for="Field16" id="title16">Company<small>If you’d like us to promote where you work.</small></label>
           <input class="field text medium" id="Field16" maxlength="255" name=
           "Field16" tabindex="4" type="text" value="" />
           </li>
            <li>
           <label class="desc" for="Field12" id="title12">Social URLs<small>Any social URLs we should promote you at?</small></label>
           <textarea class="field textarea medium"
           id="Field12"
           name="Field12"
           rows="5"
           spellcheck="true"
           tabindex="6"
           placeholder="twitter.com/examplename"></textarea>
           </li>
            <li>
           <label class="desc" for="Field13" id="title13" required="required">Presentation title
           <small class="instruct" id="instruct17">You can update this later if you need to.</small>
           </label>
           <input class="field text medium" id="Field13" maxlength="255" name=
           "Field13" required="required" tabindex="7" type="text" value="" />
           </li>
            <li>
           <label class="desc" for="Field14" id="title14" required="required">Abstract
           <small>This will be used on the site and meetup group. Aim for three to five sentences. Don’t worry if it changes before the event, it’s just to give us an idea!</small>
             </label>
             <textarea class="field textarea medium"
             id="Field14"
             name="Field14"
             required="required"
             rows="7"
             spellcheck="true"
             tabindex="8"></textarea>
             </li>
        <li id="foli19" class="notranslate">
       <fieldset>
       <legend id="title19" class="desc">
       I would like to present from…
       </legend>
       <input id="radioDefault_19" name="Field19" type="hidden" value="" />
       <input id="Field19_0" name="Field19" type="radio" class="field radio" value="My own computer" tabindex="7" checked="checked"     />
       <label class="choice" for="Field19_0" >
       My own computer</label>
       <input id="Field19_1" name="Field19" type="radio" class="field radio" value="Kenneth&rsquo;s computer" tabindex="8"     />
       <label class="choice" for="Field19_1" >
       Kenneth’s computer</label>
       </fieldset>
       </li>
        <li>
       <label class="desc" for="Field15" id="title15">Additional details
       <small class="instruct" id="instruct15">Anything else you’d like to
       tell us about your presentation? This will be excluded from the public
       summary.</small>
       </label>
       <textarea class="field textarea medium"
       id="Field15"
       name="Field15"
       rows="7"
       spellcheck="true"
       tabindex="9"></textarea>
       </li>
       <li>
       <input class="btTxt submit" id="saveForm" name="saveForm" type="submit" value="Submit" />
       </li>
        <li class="hidden">
       <label for="comment">Do Not Fill This Out</label>
       <textarea name="comment" id="comment" rows="1" cols="1"></textarea>
       <input type="hidden" id="idstamp" name="idstamp" value="eQEyBxPune3dyPc/XZ19OEcinFQfqJcNrhq4spXbBXw=" />
       </li>
      </ul>
      </form>
      */
    }
    <hr></hr>
    <div id="more-details"></div>
    <h2>{`Venue`}</h2>
    <p>{`The venue address will be on the most recent event on `}<a parentName="p" {...{
        "href": "http://meetup.com/typebrigade"
      }}>{`the Type Brigade Meetup.com page`}</a>{`, once we’ve announced it.`}</p>
    <ul>
      <li parentName="ul">{`Speakers and organisers are encouraged to arrive between 6:00–6:30`}</li>
      <li parentName="ul">{`Attendees are admitted at 6:30`}</li>
      <li parentName="ul">{`Kenneth will introduce the first talk at 7:00`}</li>
      <li parentName="ul">{`The even usually ends around 9:00, and anyone interested goes for a drink afterwards`}</li>
    </ul>
    {
      /*
      <figure class="figure--aside">
      ![](/images/mobify-hq.jpg)
      <figcaption>Mobify HQ is on the 3rd floor of the Chintz & Company building.</figcaption>
      </figure>
      */
    }
    <figure className="figure--aside">
![](/images/event-22.jpg)
      <figcaption>One of the spaces we’ve hosted Type Brigade in.</figcaption>
    </figure>
    <h3>{`Slides`}</h3>
    <p>{`We should be able to accommodate any standard slide resolution, but the optimal one for the projector is 1920×1080.`}</p>
    <p>{`We prefer if speakers `}<a parentName="p" {...{
        "href": "mailto:kenneth@typebrigade.com"
      }}>{`send Kenneth slides`}</a>{` the day before the event if possible. He’ll set them up on his computer, so they’re already ready for you at the venue. This way, we don’t have to find additional cables or connectors and don’t have to change anything over between talks. Formats we’ve used without trouble:`}</p>
    <ul>
      <li parentName="ul">{`PDF`}</li>
      <li parentName="ul">{`Keynote (latest and ‘09)`}</li>
      <li parentName="ul">{`Anything web based (Slid.es, Google Docs, etc.)`}</li>
    </ul>
    <p>{`We don’t use mirrored displays, so you can use speaker notes if you’d like. If you have another format, just let us know and we’ll do our best to accommodate it. You are also welcome to present using your own computer, but please make sure to arrive with enough time for us to test your machine with the projectors.`}</p>
    <h3>{`Other equipment`}</h3>
    <p>{`We will also have the following equipment available:`}</p>
    <ul>
      <li parentName="ul">{`One remote control with your slides`}</li>
      <li parentName="ul">{`Two microphones`}</li>
    </ul>
    <h2>{`Code of Conduct`}</h2>
    <p>{`We maintain a `}<a parentName="p" {...{
        "href": "http://typebrigade.com/code-of-conduct"
      }}>{`Code of Conduct`}</a>{` for Type Brigade, which establishes expectations for all organisers, speakers, sponsors, and attendees. It’s a continuous work in progress, with the goal of ensuring Type Brigade is a safe and welcoming community event with clearly established anti-harassment and anti-intimidation considerations.`}</p>
    <p>{`It’s a record based upon on the work of other events we admire. Running a safe and welcoming event is something we take very seriously, so if you have any positive or negative comments on the Code of Conduct or our approach in general, please let either `}<a parentName="p" {...{
        "href": "mailto:kenneth@typebrigade.com"
      }}>{`Kenneth`}</a>{` or `}<a parentName="p" {...{
        "href": "mailto:alanna@typebrigade.com"
      }}>{`Alanna`}</a>{` know.`}</p>
    <h2>{`Attendees`}</h2>
    <p>{`We average 100 attendees per event, and have been steadily growing since June 2014. It’s about one half professional and student graphic and interaction designers, a small group of aspiring type designers and letterers, and the remainder work outside of the industry but have a strong interest in typography.`}</p>
    <p>{`(If you’re interested, you can also see `}<a parentName="p" {...{
        "href": "http://www.meetup.com/typebrigade/members/"
      }}>{`our full member list`}</a>{` to get a sense of who will be there.)`}</p>
    <h3>{`Promotion`}</h3>
    <p>{`If there’s anything you’d like us to promote at the event on your behalf (a specific blog you maintain, a product you work on, your freelance work, etc.) we’d be more than happy to do what we can. Just email `}<a parentName="p" {...{
        "href": "mailto:kenneth@typebrigade.com"
      }}>{`Kenneth`}</a>{` with any details.`}</p>
    <p>{`We have pretty solid attendance for the event, but any on-going promotion you’re willing to help us out with (`}<a parentName="p" {...{
        "href": "http://twitter.com/typebrigade"
      }}>{`retweeting @typebrigade`}</a>{`, telling co-workers, listing your talk with us on your site, etc.) is always appreciated.`}</p>
    <h2>{`Questions?`}</h2>
    <p>{`As always, if you have any questions, just email `}<a parentName="p" {...{
        "href": "mailto:kenneth@typebrigade.com"
      }}>{`Kenneth`}</a>{`. Thanks again for being part of Type Brigade.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      